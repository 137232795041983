/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useImperativeHandle, useRef } from "react";
import { showToast } from "utils/common";
import { forwardRef } from "react";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import EventServices from "services/events";
import { Button } from "primereact/button";
import { v4 as uuidv4 } from "uuid";
import { ColorPicker } from "primereact/colorpicker";
import { Dropdown } from "primereact/dropdown";
import SeatServices from "services/seats";
import {
  DateType,
  Discount,
  EventDataDoc,
  SeatOption,
  ShowtimeItemType,
  ShowtimeType,
} from "types/event";
import { SeatsMapDataDoc } from "types/seats-map";
import { InputNumber } from "primereact/inputnumber";
import { Panel } from "primereact/panel";
import VUpload from "components/v-upload";
import { COMMON_STATUS, PUBLISH_STATUS } from "utils/enum";
import { PUBLISH_OPTIONS } from "utils/constant";
import ThemesService from "services/themes";
import ReactQuillEditor from "react-quill";
import ReactQuill from "react-quill";
import { InputTextarea } from "primereact/inputtextarea";

const discountInitItem: Discount = { name: "", min: 0, max: 0, percent: 0 };

type DatesTempType = {
  dates: DateType[];
  showtime: ShowtimeItemType[];
};

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData: EventDataDoc = {
    slug: "",
    name: "",
    dates: [],
    showtime: [],
    place: "",
    artists: "",
    introduction: "",
    status: "coming_soon",
    seatOptions: [],
    seat_map_id: null,
    template_id: null,
    stage_id: null,
    theme_id: null,
    thumbnail: "",
    seat_map_image: "",
    seat_map_title: "",
    theater: "",
    publish_status: PUBLISH_STATUS.DRAFT,
    discounts: [discountInitItem],
    description: "",
    type: null,
  };

  const editorRef = useRef<ReactQuill>(null);
  const [themes, setThemes] = useState([]);
  const [details, setDetails] = useState<EventDataDoc>(emptyData);
  const [seats, setSeats] = useState<SeatOption[]>([]);
  const [seatsMapTemplate, setSeatsMapTemplate] = useState<SeatsMapDataDoc[]>(
    []
  );
  const [datesTemp, setDatesTemp] = useState<DatesTempType[]>([
    {
      dates: [],
      showtime: [
        {
          id: uuidv4(),
          status: COMMON_STATUS.ENABLE,
          startTime: "",
        },
      ],
    },
  ]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  useEffect(() => {
    SeatServices.getAll().then((resp) => {
      if (resp.length) {
        const seatsMapTemplate = resp.filter((item) => item.isTemplate);
        setSeatsMapTemplate(seatsMapTemplate);
      }
    });
    getThemes();
  }, []);

  useEffect(() => {
    if (data) {
      setDetails(data);
      setSeats(data?.seatOptions);
      setDatesTemp(data?.datesTemp || []);
    }
  }, [data]);

  const getThemes = async () => {
    const res: any = await ThemesService.getAll();
    setThemes(res);
  };

  const onChange = (value, name, field = null) => {
    let _details = { ...details };
    if (field) {
      _details[field][`${name}`] = value;
    } else {
      _details[`${name}`] = value;
    }
    setDetails(_details);
  };

  const onChangeDates = (value: Date[], index: number) => {
    const cloneDateTemp = Array.from(datesTemp);
    if (!value) {
      cloneDateTemp[index].dates = [];
      return setDatesTemp(cloneDateTemp);
    }
    if (value.length > cloneDateTemp[index].dates?.length) {
      const lastValue = value.pop();
      let temp: DateType;
      const cloneArr = Array.from(datesTemp);
      console.log({
        cloneArr,
      });

      cloneArr.map((item) =>
        item.dates.map((d) => {
          if (d?.date?.toString() === lastValue?.toString()) {
            temp = d;
            return item;
          }
          return item;
        })
      );
      cloneDateTemp[index] = {
        ...cloneDateTemp[index],
        dates: [
          ...datesTemp[index].dates,
          {
            id: temp?.id || String(new Date(lastValue).getTime()),
            date: lastValue,
          },
        ],
      };
    } else {
      cloneDateTemp[index].dates = cloneDateTemp[index].dates.filter((item) =>
        value.find((v) => {
          return v.getTime() === new Date(item.date).getTime();
        })
      );
    }

    cloneDateTemp.map((item) =>
      item.dates.sort(
        (a, b) => new Date(a.date)?.getTime() - new Date(b.date)?.getTime()
      )
    );

    return setDatesTemp((prev) => [...cloneDateTemp]);
  };

  const onChangeShowTime = (
    value: string,
    fieldName: string,
    parentIndex: number,
    childIndex: number
  ) => {
    const cloneData = Array.from(datesTemp);
    cloneData[parentIndex].showtime[childIndex][fieldName] = value;
    return setDatesTemp(cloneData);
  };

  const handleAddShowTime = (index: number) => {
    const newItem = {
      id: uuidv4(),
      status: COMMON_STATUS.ENABLE,
      startTime: "",
    };
    const cloneData = Array.from(datesTemp);
    cloneData[index].showtime = [...cloneData[index].showtime, newItem];
    return setDatesTemp(cloneData);
  };

  const handleAddDate = () => {
    setDatesTemp((prev) => [
      ...prev,
      {
        dates: [],
        showtime: [
          {
            id: uuidv4(),
            status: COMMON_STATUS.ENABLE,
            startTime: "",
          },
        ],
      },
    ]);
  };

  const handleRemoveShowtime = (parentIndex: number, childIndex?: number) => {
    const cloneData = Array.from(datesTemp);
    if (typeof childIndex === "number") {
      cloneData[parentIndex].showtime = cloneData[parentIndex].showtime.filter(
        (_, index) => index !== childIndex
      );
      return setDatesTemp(cloneData);
    }

    setDatesTemp(cloneData.filter((_, index) => index !== parentIndex));
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _details = { ...details };
    _details[`${name}`] = val;
    setDetails(_details);
  };

  const handleAddSeat = () => {
    setSeats((prev) => [
      ...prev,
      {
        id: uuidv4(),
        name: "",
        color: "#ffffff",
        background: "",
        price: 0,
        original_price: 0,
        zone: "",
      },
    ]);
  };

  const handleDeleteSeat = (index: number) => {
    const cloneSeats = Array.from(seats);
    setSeats(cloneSeats.filter((_, i) => i !== index));
  };

  const onChangeSeat = (
    value: string | number,
    index: number,
    field: string
  ) => {
    const cloneSeats = Array.from(seats);
    cloneSeats[index][field] = value;
    setSeats(cloneSeats);
  };

  const onChangeDiscount = (
    value: string | number,
    index: number,
    field: string
  ) => {
    setDetails((prev) => ({
      ...prev,
      discounts: prev.discounts.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      ),
    }));
  };

  const handleAddDiscount = () => {
    setDetails((prev) => ({
      ...prev,
      discounts: [...prev.discounts, discountInitItem],
    }));
  };

  const handleRemoveDiscount = (index: number) => {
    setDetails((prev) => ({
      ...prev,
      discounts: prev.discounts.filter((_, i) => i !== index),
    }));
  };

  const submit = async () => {
    let dateArr: DateType[] = [];

    let showtimeArr: ShowtimeType[] = [];

    datesTemp.map((item) =>
      item.dates?.map((i) => {
        if (dateArr?.length) {
          const isExistedId = dateArr.find((d) => d?.id === i?.id);
          if (!isExistedId) dateArr.push(i);
        } else {
          dateArr.push(i);
        }
        return i;
      })
    );

    datesTemp.map((item) => {
      item.dates.map((dateItem) => {
        item.showtime.map((s) => {
          if (!showtimeArr?.length) {
            showtimeArr.push({
              date_id: dateItem.id,
              data: [s],
            });
          } else {
            const existedDateIndex = showtimeArr.findIndex(
              (a) => a.date_id === dateItem.id
            );
            if (existedDateIndex !== -1) {
              showtimeArr[existedDateIndex] = {
                ...showtimeArr[existedDateIndex],
                data: [
                  ...showtimeArr[existedDateIndex].data,
                  {
                    ...s,
                  },
                ],
              };
            } else {
              showtimeArr.push({
                date_id: dateItem.id,
                data: [s],
              });
            }
          }

          return s;
        });
        return dateItem;
      });
      return item;
    });

    try {
      setLoading(true);
      if (data) {
        await EventServices.updateEvent({
          params: {
            id: data._id,
          },
          body: {
            ...details,
            seatOptions: seats,
            dates: dateArr,
            showtime: showtimeArr,
            datesTemp,
          },
        });
      } else {
        await EventServices.createEvent({
          body: {
            ...details,
            seatOptions: seats,
            dates: dateArr,
            showtime: showtimeArr,
            datesTemp,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Event saved!");
      // onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  const isFreeType = details?.type === "free";

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="ticket_sale_method">Ticket Sale Method</label>
        <Dropdown
          value={details?.type}
          options={[
            { label: "SELLING BY SEAT", value: "seat" },
            { label: "SELLING BY FREE", value: "free" },
          ]}
          onChange={(e) => onChange(e.value, "type")}
          optionLabel="label"
          placeholder="Select sale method"
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="slug">Slug</label>
        <InputText
          id="slug"
          value={details.slug}
          onChange={(e) => onInputChange(e, "slug")}
          placeholder="/cam-xuc-bat-tan"
          required
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="description">Seats Map Template</label>
        <Dropdown
          value={details.template_id}
          options={seatsMapTemplate.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          onChange={(e) => onChange(e.value, "template_id")}
          optionLabel="label"
          placeholder="Select template"
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="description">Theme</label>
        <Dropdown
          value={details.theme_id}
          options={themes.map((item) => ({
            label: item.name,
            value: item._id,
          }))}
          onChange={(e) => onChange(e.value, "theme_id")}
          optionLabel="label"
          placeholder="Select theme"
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="description">Publish Status</label>
        <Dropdown
          value={details.publish_status}
          options={PUBLISH_OPTIONS}
          onChange={(e) => onChange(e.value, "publish_status")}
          optionLabel="label"
          placeholder="Select status"
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="name">Event Name</label>
        <InputText
          id="name"
          value={details.name}
          onChange={(e) => onInputChange(e, "name")}
          placeholder="Cảm xúc bất tận"
          required
        />
      </div>
      <div className="field col-12 md:col-4">
        <label htmlFor="place">Place</label>
        <InputText
          id="place"
          value={details.place}
          onChange={(e) => onInputChange(e, "place")}
          placeholder="Nhà hát Trưng Vương"
          required
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="artists">Artists</label>
        <InputText
          id="artists"
          value={details.artists}
          onChange={(e) => onInputChange(e, "artists")}
          required
          placeholder="Artist"
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="seat_map_title">Seat Map Title</label>
        <InputText
          id="seat_map_title"
          value={details.seat_map_title}
          onChange={(e) => onInputChange(e, "seat_map_title")}
          placeholder="Sơ đồ nhà hát Trưng Vương"
          required
        />
      </div>
      <div className="field col-12 ">
        <label htmlFor="introduction">Desciption (Meta)</label>
        <InputTextarea
          id="description"
          value={details.description}
          onChange={(e) => onInputChange(e, "description")}
          required
          placeholder="description"
        />
      </div>

      <div className="field col-12 md:col-6">
        <label htmlFor="" className="w-full text-center">
          Thumbnail
        </label>
        <VUpload
          isObject={true}
          objectFile={details.thumbnail}
          setObjectFile={(o) => onChange(o, "thumbnail")}
          fileType="image"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="" className="w-full text-center">
          Seat Map Image
        </label>
        <VUpload
          isObject={true}
          objectFile={details.seat_map_image}
          setObjectFile={(o) => onChange(o, "seat_map_image")}
          fileType="image"
        />
      </div>
      <div className="field col-12 md:col-6">
        <label htmlFor="" className="w-full text-center">
          Theater Panorama
        </label>
        <VUpload
          isObject={true}
          objectFile={details.theater}
          setObjectFile={(o) => onChange(o, "theater")}
          fileType="image"
        />
      </div>

      <Panel
        header="Introduction"
        toggleable
        collapsed={true}
        className="col-12"
        style={{
          overflow: "auto",
        }}
      >
        <div className="w-full">
          <div className="field col-12">
            <ReactQuillEditor
              ref={editorRef}
              value={details.introduction}
              onChange={(e) => {
                onChange(e, "introduction");
              }}
              style={{ height: "500px", marginBottom: "40px" }}
              modules={{
                toolbar: [
                  [{ header: [1, 2, 3, false] }],
                  ["bold", "italic", "underline"],
                  [{ color: [] }, { background: [] }, { size: [] }],
                  [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                  [{ script: "sub" }, { script: "super" }],
                  [{ indent: "-1" }, { indent: "+1" }],
                  ["direction", { align: [] }],
                  ["link", "image", "video", "formula"],
                  ["clean"],
                ],
              }}
            />
          </div>
        </div>
      </Panel>

      <Panel
        header="Showtime Settings"
        toggleable
        collapsed={true}
        className="col-12"
        style={{
          overflow: "auto",
        }}
      >
        <div
          className="flex flex-column w-full align-items-center"
          style={{
            maxHeight: 500,
            width: "100%",
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          {datesTemp?.map((item, parentIndex) => (
            <div className="grid w-full align-items-start" key={parentIndex}>
              <div className="field col-12 md:col-6">
                <label htmlFor="description">Dates</label>
                <Calendar
                  inline
                  className="w-full"
                  dateFormat="dd/mm/yy"
                  placeholder="Select date"
                  value={
                    item.dates?.length
                      ? item.dates.map((dateItem) =>
                          dateItem.date ? new Date(dateItem.date) : null
                        )
                      : []
                  }
                  onChange={(e: any) => onChangeDates(e.value, parentIndex)}
                  minDate={new Date()}
                  selectionMode="multiple"
                  showButtonBar
                />
              </div>

              <div className="field col-12 md:col-6">
                {item.showtime.map((time, childIndex) => (
                  <div key={childIndex} className="grid w-full align-items-end">
                    <div className="field col-10">
                      <label htmlFor="">Start Time</label>
                      <InputText
                        type="time"
                        id={`startTime-${childIndex}`}
                        value={time.startTime}
                        onChange={(e) =>
                          onChangeShowTime(
                            e.target.value,
                            "startTime",
                            parentIndex,
                            childIndex
                          )
                        }
                        required
                      />
                    </div>
                    <div className="field flex flex-column align-items-center justify-center col-2">
                      <Button
                        onClick={() =>
                          handleRemoveShowtime(parentIndex, childIndex)
                        }
                        icon="pi pi-times"
                        className="p-button-rounded p-button-danger"
                        aria-label="Remove Showtime"
                      />
                    </div>
                  </div>
                ))}
                <div
                  className="field col-12 flex align-items-center mt-4"
                  style={{
                    columnGap: 8,
                  }}
                >
                  <Button
                    onClick={() => handleRemoveShowtime(parentIndex)}
                    icon="pi pi-times"
                    style={{
                      width: 40,
                      height: 40,
                    }}
                    className="p-button-rounded p-button-danger flex align-items-center"
                    aria-label="Remove Discount"
                  />
                  <Button
                    onClick={() => handleAddShowTime(parentIndex)}
                    icon="bx bxs-add-to-queue"
                    className="p-button-rounded p-button-success flex align-items-center"
                    style={{
                      width: 40,
                      height: 40,
                    }}
                    aria-label="Search"
                  ></Button>
                </div>
              </div>
            </div>
          ))}

          <div className="field col-12">
            <Button
              onClick={() => handleAddDate()}
              icon="bx bxs-add-to-queue"
              className="p-button-rounded p-button-success mb-3 flex align-items-center"
              style={{
                width: "fit-content",
                columnGap: 4,
              }}
              aria-label="Search"
            >
              Add Date
            </Button>
          </div>
        </div>
      </Panel>
      <Panel
        header="Seat options setting"
        toggleable
        collapsed={true}
        className="col-12"
      >
        <div
          style={{
            width: "100%",
            maxHeight: 500,
            overflowX: "hidden",
            overflowY: "auto",
          }}
        >
          {seats?.map((seat, index) => (
            <div className="grid w-full mb-5" key={index}>
              <div className={`field col-12 md:col-3`}>
                <label htmlFor="name">Seat Name</label>
                <InputText
                  id="name"
                  value={seat?.name}
                  onChange={(e) => onChangeSeat(e.target.value, index, "name")}
                  required
                  autoFocus
                />
              </div>
              <div className={`field col-12 md:col-3`}>
                <label htmlFor="zone">Seat Zone</label>
                <InputText
                  id="zone"
                  value={seat?.zone}
                  onChange={(e) => onChangeSeat(e.target.value, index, "zone")}
                  required
                  autoFocus
                />
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="amount">Amount</label>
                <InputNumber
                  id="amount"
                  value={seat?.amount}
                  onChange={(e) => onChangeSeat(e.value, index, "amount")}
                  autoFocus
                  placeholder="Amount"
                />
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="price">Original price</label>
                <InputNumber
                  id="original_price"
                  value={+seat?.original_price || 0}
                  onChange={(e) =>
                    onChangeSeat(+e.value, index, "original_price")
                  }
                  required
                  autoFocus
                />
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="price">Manual Price</label>
                <InputNumber
                  id="price"
                  value={+seat?.price || 0}
                  onChange={(e) => onChangeSeat(+e.value, index, "price")}
                  required
                  autoFocus
                />
              </div>
              <div className="field flex flex-column col-12 md:col-4">
                <label>Text Color</label>
                <div className="flex align-items-center">
                  <InputText
                    id="color"
                    value={seat?.color}
                    onChange={(e) =>
                      onChangeSeat(e.target.value, index, "color")
                    }
                    required
                    autoFocus
                    className="mr-3"
                  />
                  <ColorPicker
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: 8,
                    }}
                    value={seat?.color}
                    onChange={(e) =>
                      onChangeSeat(
                        `#${e.target.value as string}`,
                        index,
                        "color"
                      )
                    }
                  />
                </div>
              </div>
              <div className="field flex flex-column col-12 md:col-4">
                <label>Background Color</label>
                <div className="flex align-items-center">
                  <InputText
                    id="color"
                    value={seat?.background}
                    onChange={(e) =>
                      onChangeSeat(e.target.value, index, "background")
                    }
                    required
                    autoFocus
                    className="mr-3"
                  />
                  <ColorPicker
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: 8,
                    }}
                    value={seat?.background}
                    onChange={(e) =>
                      onChangeSeat(
                        `#${e.target.value as string}`,
                        index,
                        "background"
                      )
                    }
                  />
                </div>
              </div>
              <div className="field flex flex-column align-items-center justify-center col-12 md:col-2">
                <Button
                  onClick={() => handleDeleteSeat(index)}
                  icon="pi pi-times"
                  className="p-button-rounded p-button-danger"
                  aria-label="Search"
                />
              </div>
              <div className="field col-12">
                <VUpload
                  isObject={true}
                  objectFile={seat?.thumbnail}
                  setObjectFile={(o) => onChangeSeat(o, index, "thumbnail")}
                  fileType="image"
                />
              </div>
            </div>
          ))}
          <div className="field col-12">
            <Button
              onClick={handleAddSeat}
              icon="bx bxs-add-to-queue"
              className="p-button-rounded p-button-success mb-3 flex align-items-center"
              style={{
                width: "fit-content",
                columnGap: 4,
              }}
              aria-label="Search"
            >
              Add Seat Option
            </Button>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default forwardRef(Details);
