/* eslint-disable react-hooks/exhaustive-deps */
import { InputText } from "primereact/inputtext";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { showToast } from "utils/common";
import { Dropdown } from "primereact/dropdown";
import { TICKET_OPTIONS } from "utils/constant";
import { TicketDataDoc } from "types/ticket";
import { SeatMap } from "types/seats-map";
import TicketServices from "services/tickets";

const Details = (props, ref) => {
  const { data, reload, toast, onCancel, setLoading } = props;

  const emptyData: TicketDataDoc = {
    _id: undefined,
    id: undefined,
    order_id: "",
    event_name: "",
    seat_code: "",
    showtime: "",
    email: "",
    seat: {} as SeatMap,
    status: "",
    check_in_date: new Date(),
    qr_code: "",
    user_scan: "",
  };

  const [details, setDetails] = useState<TicketDataDoc>(emptyData);

  useImperativeHandle(ref, () => ({
    submit: () => {
      submit();
    },
  }));

  const onInputChange = (value, name) => {
    setDetails((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const submit = async () => {
    try {
      setLoading(true);
      if (data) {
        await TicketServices.updateTicket({
          params: {
            id: data._id,
          },
          body: {
            ...details,
          },
        });
      } else {
        await TicketServices.createTicket({
          body: {
            ...details,
          },
        });
      }
      setLoading(false);
      showToast(toast, "success", "Order saved!");
      onCancel();
      reload();
    } catch (error) {
      setLoading(false);
      showToast(toast, "error", error.errors);
    }
  };

  useEffect(() => {
    if (data) {
      setDetails(data);
    }
  }, [data]);

  return (
    <div className="grid">
      <div className="field col-12">
        <label htmlFor="title">Seat Code</label>
        <InputText
          id="seat_code"
          value={details.seat_code}
          onChange={(e) => onInputChange(e.target.value, "seat_code")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12 ">
        <label htmlFor="title">Email</label>
        <InputText
          id="email"
          value={details?.email}
          onChange={(e) => onInputChange(e.target.value, "email")}
          required
          autoFocus
        />
      </div>

      <div className="field col-12">
        <label htmlFor="title">Seat Number</label>
        <InputText
          id="seat_number"
          value={details?.seat?.name}
          onChange={(e) => onInputChange(e.target.value, "seat_number")}
          required
          autoFocus
        />
      </div>
      <div className="field col-12">
        <label htmlFor="title">Phone Number</label>
        <InputText
          id="phone_number"
          value={details.event_name}
          onChange={(e) => onInputChange(e.target.value, "phone_number")}
          required
          autoFocus
        />
      </div>
      {/* <div className="field col-12">
        <label htmlFor="title">Show Time</label>
        <InputText
          id="showtime"
          value={details.showtime}
          onChange={(e) => onInputChange(e.target.value, "showtime")}
          required
          autoFocus
        />
      </div> */}
      <div className="field col-12">
        <label htmlFor="title">Status</label>
        <Dropdown
          value={details.status}
          options={TICKET_OPTIONS}
          optionLabel="label"
          placeholder="Status"
          showClear
          className={"w-full"}
        />
      </div>
    </div>
  );
};

export default forwardRef(Details);
