/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, showToast } from "utils/common";
import { Calendar } from "primereact/calendar";
import debounce from "lodash.debounce";
import { Panel } from "primereact";
import OrderServices from "services/orders";
import { OrderDataDoc } from "types/order";
import { PAYMENT_STATUS_OPTIONS } from "utils/constant";
import { Dropdown } from "primereact/dropdown";
import EventServices from "services/events";
import { EventDataDoc } from "types/event";
import VConfirm from "components/v-confirm";
import Details from "./components/Details";
import VDialog from "components/v-dialog";

const View = () => {
  const [data, setData] = useState<OrderDataDoc[]>([]);
  const [detail, setDetail] = useState<OrderDataDoc | null>(null);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    event_id: "",
    payment_status: "success",
    start: null,
    end: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [events, setEvents] = useState<EventDataDoc[]>([]);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);
  const refDetail = useRef(null);

  const loadData = async () => {
    try {
      const res = await OrderServices.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };

  const handleDelete = async () => {
    try {
      if (selectedOrders.length > 0) {
        await Promise.all(
          selectedOrders.map((order) =>
            OrderServices.deleteOrder({ params: { id: order._id } })
          )
        );
        setData((prevData) =>
          prevData.filter(
            (order) =>
              !selectedOrders.some((_order) => order._id === _order._id)
          )
        );
        setSelectedOrders([]);
        showToast(toast, "success", "Selected orders deleted");
      } else if (detail?._id) {
        await OrderServices.deleteOrder({ params: { id: detail._id } });
        setData((prevData) =>
          prevData.filter((order) => order._id !== detail._id)
        );
        setDetail(null);
        showToast(toast, "success", "Order deleted");
      }
      refDialogDelete.current.close();
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  const renderBookedSeats = (rowData: OrderDataDoc) => {
    const { booking_seats, seats_map }: any = rowData;
    if (seats_map) {
      const flatSeats = seats_map?.seats?.flat().flat();
      const seats = flatSeats
        .filter((item) => booking_seats?.includes(item.id))
        ?.map((item) => item.name);
      return seats.join(", ");
    }
  };

  useEffect(() => {
    EventServices.getAll().then((resp) => {
      if (resp?.length) {
        setEvents(resp);
      }
    });
  }, []);

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editProduct = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  //RENDER
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            // disabled={true}
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            onClick={confirmDelete}
            disabled={selectedOrders.length === 0}
          />
        </div>
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions flex items-center">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning mr-2"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <div className="grid my-2 align-items-center w-full">
          <span className="block p-input-icon-left col-12 md:col-4">
            <i className="ml-1 pi pi-search" />
            <InputText
              className="w-full"
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
            />
          </span>{" "}
          <div className="col-12 md:col-4">
            <Calendar
              className="w-full"
              id="time24"
              placeholder="Start Date"
              value={globalFilter.start}
              onChange={(e: any) => onSearch("start", e.value)}
              maxDate={new Date()}
              showButtonBar
            />{" "}
          </div>
          <div className="col-12 md:col-4">
            <Calendar
              className="w-full"
              id="time24"
              placeholder="End Date"
              value={globalFilter.end}
              onChange={(e: any) => onSearch("end", e.value)}
              maxDate={new Date()}
              showButtonBar
            />
          </div>
          <div className="col-12 md:col-6">
            <Dropdown
              value={globalFilter.payment_status}
              options={PAYMENT_STATUS_OPTIONS}
              onChange={(e: any) => onSearch("payment_status", e.value)}
              optionLabel="label"
              placeholder="Status"
              showClear
              className={"w-full"}
            />
          </div>
          <div className="col-12 md:col-6">
            <Dropdown
              value={globalFilter.event_id}
              options={events?.map((item) => ({
                label: item.name,
                value: item._id,
              }))}
              onChange={(e: any) => onSearch("event_id", e.value)}
              optionLabel="label"
              placeholder="Event"
              showClear
              className={"w-full"}
            />
          </div>
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="_id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
            selectionMode={"checkbox"}
            selection={selectedOrders}
            onSelectionChange={(e) => setSelectedOrders(e.value)}
          >
            <Column
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "120px" }}
            ></Column>
            <Column
              selectionMode="multiple"
              style={{ flexGrow: 1, flexBasis: "40px" }}
            ></Column>
            <Column
              field="order_code"
              header="Order Code"
              style={{ flexGrow: 1, flexBasis: "120px" }}
            ></Column>
            <Column
              field="event"
              header="Event"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) => rowData?.event?.name}
            ></Column>
            <Column
              field="phone_number"
              header="Phone Number"
              style={{ flexGrow: 1, flexBasis: "120px" }}
            ></Column>
            <Column
              field="email"
              header="Email"
              style={{
                flexGrow: 1,
                flexBasis: "200px",
                textOverflow: "ellipsis",
                overflowX: "auto",
                scrollbarWidth: "none",
                whiteSpace: "nowrap",
              }}
            ></Column>
            <Column
              field="amount_ticket"
              header="Amount Ticket"
              style={{ flexGrow: 1, flexBasis: "120px" }}
            ></Column>
            <Column
              field="booking_seats"
              header="Booked Seats"
              style={{ flexGrow: 1, flexBasis: "120px" }}
              body={(rowData) => renderBookedSeats(rowData)}
            ></Column>
            <Column
              field="payment_status"
              header="Payment Status"
              style={{ flexGrow: 1, flexBasis: "120px" }}
              body={(rowData) =>
                PAYMENT_STATUS_OPTIONS.find(
                  (item) => item.value === rowData?.payment_status
                )?.label
              }
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>
          <VDialog
            ref={refDialogDetail}
            header="Details"
            onSubmit={onSumitDialogDetail}
            closeOnEscape={false}
          >
            <Details
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>
          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
