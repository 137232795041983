/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { formatLocalTime, formatNumber, showToast } from "utils/common";
import Details from "./components/Details";
import VDialog from "components/v-dialog";
import { Calendar } from "primereact/calendar";
import debounce from "lodash.debounce";
import { Panel } from "primereact";
import VConfirm from "components/v-confirm";
import VoucherServices from "services/vouchers";
import { VoucherDataDoc } from "types/voucher";
import { Dropdown } from "primereact/dropdown";
import { COMMON_STATUS_OPTIONS } from "utils/constant";

const View = () => {
  const [data, setData] = useState<VoucherDataDoc[]>([]);
  const [detail, setDetail] = useState<VoucherDataDoc | null>(null);
  const [globalFilter, setGlobalFilter] = useState({
    search: null,
    status: "enable",
    start: null,
    end: null,
  });
  const [lazyParams, setLazyParams]: any = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: "createdAt",
    sortOrder: -1,
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const toast = useRef(null);
  const dt = useRef(null);
  const refDialogDetail = useRef(null);
  const refDialogDelete = useRef(null);

  const refDetail = useRef(null);

  const loadData = async () => {
    try {
      const res = await VoucherServices.search({
        query: {
          ...lazyParams,
          ...globalFilter,
          page: lazyParams.page + 1,
        },
      });
      if (res && res.docs) {
        setData(res.docs);
        setTotalRecords(res.totalDocs);
      } else setData([]);
    } catch (error) {
      setData([]);
    }
  };

  //TABLE EVENT
  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };
  const onFilter = (name, value) => {
    setGlobalFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const onSearch = useCallback(debounce(onFilter, 500), []);

  //CREATE OR NEW
  const openNew = () => {
    setDetail(null);
    refDialogDetail.current.show();
  };
  const editProduct = (data) => {
    setDetail({ ...data });
    refDialogDetail.current.show();
  };
  const onCancelDialogDetail = () => {
    refDialogDetail.current.close();
  };
  const onSumitDialogDetail = () => {
    refDetail.current.submit();
  };
  const setLoadingSaveDetail = (flg) => {
    refDialogDetail.current.setLoading(flg);
  };

  // DELETE
  const confirmDelete = (product) => {
    setDetail(product);
    refDialogDelete.current.show();
  };

  const handleDelete = async () => {
    try {
      await VoucherServices.deleteVoucher({
        params: {
          id: detail._id,
        },
      });
      const _data = data.filter((val) => val._id !== detail._id);
      setData(_data);
      refDialogDelete.current.close();
      setDetail(null);
      showToast(toast, "success", "Voucher Deleted");
    } catch (error) {
      showToast(toast, "error", error.errors);
    }
  };

  useEffect(() => {
    loadData();
  }, [lazyParams, globalFilter]);

  //RENDER
  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <div className="my-2">
          <Button
            label="New"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openNew}
          />
          <Button
            label="Delete"
            icon="pi pi-trash"
            className="p-button-danger"
            // onClick={confirmDeleteSelected}
            // disabled={!selectedProducts || !selectedProducts.length}
            disabled={true}
          />
        </div>
      </React.Fragment>
    );
  };
  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          name="Import"
          chooseLabel="Import"
          disabled
          className="mr-2 inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          disabled
          className="p-button-help"
          // onClick={exportCSV}
        />
      </React.Fragment>
    );
  };
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-sm p-button-success mr-2"
          onClick={() => editProduct(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-sm p-button-warning mr-2"
          onClick={() => confirmDelete(rowData)}
        />
      </div>
    );
  };

  const header = (
    <Panel header="Filter" toggleable collapsed={false}>
      <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <div className="grid my-2 align-items-center w-full">
          <span className="block p-input-icon-left col-12 md:col-3">
            <i className="ml-1 pi pi-search" />
            <InputText
              className="w-full"
              type="search"
              onInput={(e: any) => onSearch("search", e.target.value)}
              placeholder="Search..."
            />
          </span>{" "}
          <div className="col-12 md:col-3">
            <Calendar
              className="w-full"
              id="time24"
              placeholder="Start Date"
              value={globalFilter.start}
              onChange={(e: any) => onSearch("start", e.value)}
              minDate={new Date()}
              showButtonBar
            />{" "}
          </div>
          <div className="col-12 md:col-3">
            <Calendar
              className="w-full"
              id="time24"
              placeholder="End Date"
              value={globalFilter.end}
              onChange={(e: any) => onSearch("end", e.value)}
              minDate={new Date()}
              showButtonBar
            />
          </div>
          <div className="col-12 md:col-3">
            <Dropdown
              value={globalFilter.status}
              options={COMMON_STATUS_OPTIONS}
              onChange={(e: any) => onSearch("status", e.value)}
              optionLabel="name"
              placeholder="Status"
              showClear
              className={"w-full"}
            />
          </div>
        </div>
      </div>
    </Panel>
  );

  return (
    <div className="grid View-demo">
      <div className="col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>
          <DataTable
            ref={dt}
            value={data}
            header={header}
            emptyMessage="No data found."
            dataKey="id"
            size="small"
            scrollDirection="both"
            className="datatable-responsive"
            scrollable
            showGridlines
            lazy
            //panigate
            paginator
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            rowsPerPageOptions={[10, 20, 50, 100]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{first} - {last} of {totalRecords}"
            onPage={onPage}
            //sort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onSort={onSort}
          >
            <Column
              body={actionBodyTemplate}
              style={{ flexGrow: 1, flexBasis: "120px" }}
            ></Column>
            <Column
              field="voucher_code"
              header="Voucher Code"
              style={{ flexGrow: 1, flexBasis: "100px" }}
            ></Column>
            <Column
              field="type"
              header="Type Code"
              style={{ flexGrow: 1, flexBasis: "100px" }}
            ></Column>
            <Column
              field="name"
              header="Name"
              style={{ flexGrow: 1, flexBasis: "150px" }}
            ></Column>
            <Column
              field="quantity"
              header="Quantity"
              style={{ flexGrow: 1, flexBasis: "100px" }}
              body={(rowData) => formatNumber(rowData.quantity, 0)}
            ></Column>
            <Column
              field="used"
              header="Used"
              style={{ flexGrow: 1, flexBasis: "50px" }}
            ></Column>
            <Column
              field="percent"
              header="Percent/Amount"
              style={{ flexGrow: 1, flexBasis: "150px" }}
              body={(rowData) =>
                rowData.type === "percent"
                  ? `${rowData.percent}%`
                  : `${formatNumber(rowData.percent, 0)} đ`
              }
            ></Column>

            <Column
              field="order_value"
              header="Order Value"
              style={{ flexGrow: 1, flexBasis: "120px" }}
              body={(rowData) =>
                `${formatNumber(rowData.order_value, 0)} ${
                  rowData.type === "percent" ? "tickets" : "đ"
                }`
              }
            ></Column>

            <Column
              field="min_order_value"
              header="Min Order Value"
              style={{ flexGrow: 1, flexBasis: "120px" }}
              body={(rowData) =>
                `${formatNumber(rowData.min_order_value, 0)} ${
                  rowData.type === "percent" ? "tickets" : "đ"
                }`
              }
            ></Column>

            <Column
              field="max_order_value"
              header="Max Order Value"
              style={{ flexGrow: 1, flexBasis: "120px" }}
              body={(rowData) =>
                `${formatNumber(rowData.max_order_value, 0)} ${
                  rowData.type === "percent" ? "tickets" : "đ"
                }`
              }
            ></Column>

            <Column
              field="event"
              header="Event"
              style={{ flexGrow: 1, flexBasis: "120px" }}
              body={(rowData) =>
                rowData?.event?.length ? rowData?.event[0]?.name : "All Events"
              }
            ></Column>

            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              header="Frame Time"
              body={(rowData) =>
                `${rowData?.start_time} - ${rowData?.end_time}`
              }
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="start_date"
              header="Start Date"
              sortable
              body={(rowData) =>
                rowData?.start_date ? formatLocalTime(rowData?.start_date) : ""
              }
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="end_date"
              header="End Date"
              sortable
              body={(rowData) =>
                rowData?.end_date ? formatLocalTime(rowData?.end_date) : ""
              }
            ></Column>
            <Column
              field="status"
              header="Status"
              style={{ flexGrow: 1, flexBasis: "120px" }}
              body={(rowData) => (
                <span className={`v-badge status-${rowData.status}`}>
                  {rowData.status}
                </span>
              )}
            ></Column>
            <Column
              style={{ flexGrow: 1, flexBasis: "100px" }}
              field="createdAt"
              header="Created At"
              sortable
              body={(rowData) => formatLocalTime(rowData.createdAt)}
            ></Column>
          </DataTable>

          <VDialog
            ref={refDialogDetail}
            header="Details"
            onSubmit={onSumitDialogDetail}
          >
            <Details
              ref={refDetail}
              data={detail}
              toast={toast}
              reload={loadData}
              setLoading={setLoadingSaveDetail}
              onCancel={onCancelDialogDetail}
            />
          </VDialog>

          <VConfirm ref={refDialogDelete} onConfirm={handleDelete} />
        </div>
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(View, comparisonFn);
